<template>
  <!-- Inicio Loader reprocessar -->
  <!-- <v-dialog v-model="loadingReprocessar" persistent width="300"> -->
  <v-dialog v-model="param.open" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text style="padding-top: 7px">
        {{ this.param.message }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- Fim Loader reprocessar -->
</template>

<script>
import Template from "../Template.vue";

export default {
  name: "Loading",
	components: {
    Template,
	},
  props: {
    param: { type: Object },
  },
};
</script>

<style>
</style>