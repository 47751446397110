<template>
  <div>
    <!-- Inicio Loader reprocessar -->
    <Loading v-if="this.loadingReprocessar" :param="itemparam" />
    <!-- Fim Loader reprocessar -->

    <v-card v-if="!this.loadingReprocessar">
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col cols="11" align="center" justify="center">
                <v-img
                  :src="`${this.image.url}${this.image.name}`"
                  cover
                  width="25%"
                  height="25%"
                  max-width="25%"
                  max-height="25%"
                ></v-img>
              </v-col>
              <v-col cols="1" align="center" justify="center">
                <v-img
                  :src="`${this.image.url}isisfrete.png`"
                  cover
                  width="50%"
                  height="50%"
                  max-width="50%"
                  max-height="50%"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" align="center" justify="center">
            <div class="timeline">
              <ol>
                <li
                  v-for="(rastreio, idx) in rastreios"
                  :key="rastreio.codigo"
                  :class="`${rastreio.color} ${rastreio.posi}`"
                >
                  {{ rastreio.descricao }}
                </li>
              </ol>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" align="center" justify="center">
            <h1>CÓDIGO DE RASTREIO: {{ this.conteudo.objeto }}</h1>
            <h2>TRANSPORTADORA: {{ this.conteudo.transporte }}</h2>
            <h4>PRAZO DE ENTREGA - ATÉ {{ this.conteudo.previsao }}</h4>
          </v-col>
        </v-row>
        <v-row align="left">
          <v-col cols="12" sm="12" align="left">
            <h5>Detalhes da Entrega</h5>
            <h5>Saindo de:</h5>
            <h5>{{ this.conteudo.origem }}</h5>
            <h5>Com destino a:</h5>
            <h5>{{ this.conteudo.destino }}</h5>
          </v-col>
        </v-row>

        <!-- Inicio Detalhes -->
        <v-row>
          <v-col cols="12" class="px-4">
            <template>
              <div>
                <v-card>
                  <v-card-text>
                    <v-row align="left">
                      <v-col cols="12" sm="12" align="left" @click="setDetails()">
                        {{ this.opDetails }}
                      </v-col>
                    </v-row>
                    <v-row v-if="details"
                      v-for="(rastreio, idx) in this.conteudo.detalhes"
                      :key="idx"
                      no-gutters
                      class="flex-nowrap bg-surface-variant"
                      align="left"
                    >
                      <v-card outline width=100% max-width=100%>
                        <v-card-text >
                          <v-sheet
                            class="d-flex mb-6 bg-surface-variant"
                            height="100"
                          >
                            <v-sheet class="ma-2 pa-2">
                              <v-row>
                                {{ rastreio.data }}
                              </v-row>
                              <v-row>
                                {{ rastreio.hora }}
                              </v-row>
                              <v-row v-if="rastreio.cidade">
                                {{ rastreio.cidade }}/{{ rastreio.estado }}
                              </v-row>
                            </v-sheet>
                            <v-sheet class="ma-2 pa-2 align-self-center">
                              {{ rastreio.descricao }}
                            </v-sheet>
                          </v-sheet>
                        </v-card-text>
                      </v-card>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </template>
          </v-col>
        </v-row>
        <!-- Fim Detalhes -->
      </v-card-text>
    </v-card>
  </div>
</template>

  <script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";
import Loading from "../components/loading/frmLoading.vue";

export default {
  name: "Reclamacoes",
  components: {
    Template,
    Loading,
  },

  props: {
    rastreio: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    reverse: false,
    details: false,
    opDetails: '+ Detalhes',
    rastreios: [
      { codigo: 1, descricao: "Postado", color: "", posi: "postado" },
      { codigo: 2, descricao: "Em Transporte", color: "", posi: "transporte" },
      { codigo: 3, descricao: "Saiu para Entrega", color: "", posi: "rotaentrega" },
      { codigo: 4, descricao: "Entregue", color: "", posi: "entrega" },
    ],
    conteudo: { objeto:'', transporte:'', previsao:'', origem:'', destino:'', qrCode:'', image:'', detalhes: [] },
    itemparam: { message: "Aguarde, Procurando...", open: false },
    loadingReprocessar: false,
    hoje: moment(new Date()).format("DD/MM/YYYY"),
    image: { url: 'https://cliente.sandbox.sisfrete.com.br/images/svg/', name: ''},
  }),
  mounted() {
    this.searchRastreio();
  },
  methods: {
    setDetails() {
      if (this.details) {
        this.details = false;
        this.opDetails = '+ Detalhes';
      } else {
        this.details = true;
        this.opDetails = '- Detalhes';
      }
    },
    async searchRastreio() {
      this.loadingReprocessar = true;
      this.itemparam.open = this.loadingReprocessar;
      await axios
      .get(`${baseApiUrl}/shipping-tracking?search=${this.rastreio}`)
      .then(res => {
        if(res.data.retorno) {
          this.conteudo.objeto = res.data.dados.objtrack;
          this.conteudo.transporte = res.data.dados.transpor;
          this.conteudo.previsao = moment(res.data.dados.previsao).format("DD/MM/yyyy");
          this.conteudo.origem = res.data.dados.source;
          this.conteudo.destino = res.data.dados.target;
          this.conteudo.detalhes = res.data.dados.details;

          this.image.name = res.data.dados.img;
          if (this.image.name == null ) {
            this.image.name = "sisfrete.png"
          }
          this.rastreios.forEach(element => {
            if(element.codigo <= Number(res.data.dados.position) ) {
              element.color = "distak";
            }
          })
        } else {
          this.$swal({
            title: 'Alerta',
            text: `${res.data.message}`,
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        }
      })
      .catch(() => {
        this.$swal({
          title: 'Alerta',
          text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        });
      })
      .finally(() => {
        this.loadingReprocessar = false;
        this.itemparam.open = this.loadingReprocessar;
      });
    }
  },
};
</script>

<style>
.distak {
  --distak-color: #018656;
  --distak-post:url("https://cliente.sandbox.sisfrete.com.br/images/img/postado.png");
  --distak-tran:url("https://cliente.sandbox.sisfrete.com.br/images/img/transporte.png");
  --distak-rota:url("https://cliente.sandbox.sisfrete.com.br/images/img/rotaentrega.png");
  --distak-entr:url("https://cliente.sandbox.sisfrete.com.br/images/img/entregue.png");
}

.postado {
    --background-image: var(--distak-post,url("https://cliente.sandbox.sisfrete.com.br/images/img/postadopb.png"));
    --background-size: 50px;
    --filter: drop-shadow(1px 1px 1px var(--distak-color) );
    color: var(--distak-color);
}

.transporte {
    --background-image: var(--distak-tran,url("https://cliente.sandbox.sisfrete.com.br/images/img/transportepb.png"));
    --background-size: 50px;
    --filter: drop-shadow(1px 1px 1px var(--distak-color) );
    color: var(--distak-color);
}

.rotaentrega {
    --background-image: var(--distak-rota,url("https://cliente.sandbox.sisfrete.com.br/images/img/rotaentregapb.png"));
    --background-size: 50px;
    --filter: drop-shadow(1px 1px 1px var(--distak-color) );
    color: var(--distak-color);
}

.entrega {
    --background-image: var(--distak-entr,url("https://cliente.sandbox.sisfrete.com.br/images/img/entreguepb.png"));
    --background-size: 50px;
    --filter: drop-shadow(1px 1px 1px var(--distak-color) );
    color: var(--distak-color);
}

.timeline {
  overflow-x: hidden;
  padding: 20px 0;
}

.timeline ol {
  width: 100%;
  transition: all 1s;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.timeline ol li {
  list-style: none;
  position: relative;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 7px;
}

.timeline ol li:before {
  content: "";
  width: 50px;
  height: 50px;
  display: block;
  filter: var(--filter);
  background-image: var( --background-image );
  background-size: var(--background-size);
  background-repeat: no-repeat;
  margin: 0 auto 5px auto;
}

.timeline ol li:not(:last-child)::after {
  content: "";
  width: calc(100% - 50px);
  height: 3px;
  display: block;
  background: var(--distak-color, #ccc);
  margin: 0;
  position: absolute;
  top: 18px;
  left: calc(50% + 23px);
}
</style>